import moment from 'moment';
import React from 'react';
import { GoChevronLeft } from 'react-icons/go';
import { useHistory, useLocation } from 'react-router-dom';
import MessagingForm from './MessagingForm';
import css from './MessagingUI.module.css';
import { InlineTextButton, Avatar } from '../../components';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import classNames from 'classnames';

const sortByTime = (a, b) =>
  new Date(a.attributes.createdAt).getTime() - new Date(b.attributes.createdAt).getTime();

const humanize = date => moment.duration(moment().diff(moment(new Date(date)))).humanize();

function MessagingUI(props) {
  const {
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    messages,
    oldestMessagePageFetched,
    onShowMoreMessages,
    totalMessagePages,
    currentUser,
    onSubmit,
    messagePlaceholder,
    inProgress,
    sendMessageError,
    otherUserDisplayName,
    rootClassName,
    isOnlineType,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();

  let hasOlderMessages = totalMessagePages > oldestMessagePageFetched;
  hasOlderMessages = hasOlderMessages && !fetchMessagesInProgress;

  const showFeed = messages.length > 0 || initialMessageFailed || fetchMessagesError;

  const initialMessageFailedComponent = initialMessageFailed ? (
    <p className={css.messageError}>
      <FormattedMessage id="TransactionPanel.initialMessageFailed" />
    </p>
  ) : null;
  const fetchMessagesErrorComponent = fetchMessagesError ? (
    <p className={css.messageError}>
      <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
    </p>
  ) : null;

  const messageListItem = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;

    const { content, createdAt } = message?.attributes ?? {};

    if (isOwnMessage) {
      return (
        <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.ownMessage}>
          <Avatar className={css.avatar} user={message.sender} disableProfileLink />
          <div className={css.innerOwnMessage}>
            <span className={css.name}>
              {intl.formatMessage({ id: 'TransactionPanel.MessagingUI.messageLabelYou' })}
            </span>
            <span className={css.date}>
              {' '}
              {intl.formatMessage(
                { id: 'TransactionPanel.MessagingUI' },
                { time: humanize(createdAt) }
              )}
            </span>
            <p className={css.message}>{content}</p>
          </div>
        </li>
      );
    }

    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.otherMessage}>
        <Avatar className={css.avatar} user={message.sender} disableProfileLink />
        <div className={css.innerOtherMessage}>
          <span className={css.name}>{otherUserDisplayName}</span>
          <span className={css.date}>
            {intl.formatMessage(
              { id: 'TransactionPanel.MessagingUI' },
              { time: humanize(createdAt) }
            )}
          </span>
          <p className={css.message}>{content}</p>
        </div>
      </li>
    );
  };

  const onNavigateToInbox = e => {
    e.preventDefault();
    e.stopPropagation();

    const pathname = location.pathname;

    return history.push(pathname.startsWith('/sale') ? '/inbox/sales' : '/inbox/orders');
  };

  return (
    <div className={classNames(css.wrapper, rootClassName)}>
      <h1 className={css.header} onClick={onNavigateToInbox}>
        <GoChevronLeft className={css.headerIcon} />
        <span>
          {intl.formatMessage(
            { id: 'TransactionPanel.MessagingUI.messageWith' },
            { otherUserDisplayName: otherUserDisplayName }
          )}{' '}
        </span>
      </h1>

      <main>
        {hasOlderMessages ? (
          <p className={css.showOlderWrapper} key="show-older-messages">
            <InlineTextButton className={css.showOlderButton} onClick={onShowMoreMessages}>
              <FormattedMessage id="ActivityFeed.showOlderMessages" />
            </InlineTextButton>
          </p>
        ) : null}

        {initialMessageFailedComponent}
        {fetchMessagesErrorComponent}

        <ul className={css.chatWrapper}>
          {// Array.from({ length: 30 })
          //   .map(v => messages)
          //   .reduce((acc, item) => [...acc, ...item], [])
          messages
            .sort(sortByTime)
            .map(message => messageListItem({ ...message, otherUserDisplayName }))}
        </ul>
        <footer className={css.sendMessageFormWrapper}>
          <MessagingForm
            onSubmit={onSubmit}
            messagePlaceholder={messagePlaceholder}
            inProgress={inProgress}
            sendMessageError={sendMessageError}
          />
        </footer>
      </main>
    </div>
  );
}

export default MessagingUI;
