import React from 'react';
import { FieldTextInput, Form, IconSpinner } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import css from './MessagingForm.module.css';
import { FiSend } from 'react-icons/fi';

function MessagingForm(props) {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          values,
          invalid,
          messagePlaceholder,
          inProgress,
          sendMessageError,
        } = fieldRenderProps;

        const submitDisabled = invalid || !values?.message || inProgress;

        return (
          <Form onSubmit={handleSubmit}>
            {sendMessageError && <p className={css.messageSendError}>{sendMessageError}</p>}
            <div className={css.root}>
              <FieldTextInput
                id="message"
                name="message"
                type="textarea"
                placeholder={messagePlaceholder}
                className={css.messageInput}
                style={{ width: '95%', padding: '1rem' }}
                disabled={inProgress}
              />

              <button
                type="submit"
                className={css.sendButton}
                data-inprogress={inProgress}
                disabled={submitDisabled}
              >
                {inProgress ? <IconSpinner className={css.loadingIcon} /> : <FiSend />}
              </button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default MessagingForm;
